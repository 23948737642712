// 全角バイトを考慮して、文字数バリデーションしてる
export function textLength(str: string | null): number {
    if (!str) {
      return 0;
    }
    let len = 0;
    const patternDefaultTag = /\{\s*\w+.*?:\s*(.*?)\s*}/ig
    const patternNoDefaultTag = /\{\s*\w+.*?}/i
    const replaced = str.replace(patternDefaultTag, '$1')
      .replace(patternNoDefaultTag, '')
    const escaped = escape(replaced);
    for (let i = 0; i < escaped.length; i++, len++) {
      if (escaped.charAt(i) === '%') {
        if (escaped.charAt(++i) === 'u') {
          i += 3;
          len++;
        }
        i++;
      }
    }
    return len;
}
