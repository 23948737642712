import {CandidateAsset, NG_TYPE, NGWord, RSAAssetImp, RSAProductAsset} from '@/stores/model/domain';
import {generateRegexPattern} from '@/utils/rsa/regex_pattern';

export const checkNGWord = (ngWord: NGWord, candidateAsset: CandidateAsset) => {
  const reg = generateRegexPattern(ngWord.ng_word)
  if (reg.test(candidateAsset.asset_text)) {
    if (ngWord.ng_type === NG_TYPE.AVAILABLE_REPLACE) {
      // replaced_wordがnullの時は何もしない
      if (!ngWord.replaced_word) {
        return candidateAsset
      }
      // replaced_wordにng_wordが含まれる & アセット文中に置換語が含まれる場合、何もしない
      // replaced_wordが元のテキストにあるかどうかの判定では大小文字は厳密に区別する
      if (ngWord.replaced_word.match(reg) &&
        candidateAsset.asset_text.match(RegExp(`${ngWord.replaced_word}`, 'g'))) {
        return candidateAsset
      }
      candidateAsset.asset_text_ng_tagged = addTagNGWord(ngWord, candidateAsset.asset_text)
      candidateAsset.ng_check_types.add(NG_TYPE.AVAILABLE_REPLACE)
      candidateAsset.asset_text = candidateAsset.asset_text.replace(reg, ngWord.replaced_word)
      candidateAsset.asset_text_default = candidateAsset.asset_text
    } else {
      candidateAsset.asset_text_ng_tagged = addTagNGWord(ngWord, candidateAsset.asset_text)
      candidateAsset.ng_check_types.add(ngWord.ng_type)
    }
  }
  return candidateAsset
}

// ChampionAd（RSAAsset）のNGワードチェックではReplaceはしない
export const checkNGWordForRSAAsset = (ngWord: NGWord, rsaAsset: RSAAssetImp) => {
  const reg = generateRegexPattern(ngWord.ng_word)
  if (reg.test(rsaAsset.word)) {
    rsaAsset.asset_text_ng_tagged = addTagNGWord(ngWord, rsaAsset.word)
    rsaAsset.ng_check_types.add(ngWord.ng_type)
  }
  return rsaAsset
}

// RSAProduct（RSAProductAsset）のNGワードチェックではReplaceはしない
export const checkNGWordForRSAProductAsset = (ngWord: NGWord, rpa: RSAProductAsset) => {
  const reg = generateRegexPattern(ngWord.ng_word)
  if (reg.test(rpa.asset_text)) {
    rpa.asset_text_ng_tagged = addTagNGWord(ngWord, rpa.asset_text)
    rpa.ng_check_types.add(ngWord.ng_type)
  }
  return rpa
}

const addTagNGWord = (ngWord: NGWord, assetText: string) => {
  const reg = generateRegexPattern(ngWord.ng_word)
  return assetText.replace(reg, taggingWord)
}

const taggingWord = (originalWord: string) => {
  return `<span class='ng_word'>${originalWord}</span>`
}
